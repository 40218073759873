import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectDocumentReferrer } from '~/store/modules/analytics/selectors'
import { selectScreenType } from '~/store/modules/dimensions/selectors'
import { setDocumentReferrer } from '~/store/modules/analytics'
import { trackPage } from '~/services/segment'
import { selectIsImpersonationAuthorized } from '~/store/modules/auth/selectors'

export const useSegment = location => {
  const referrer = useSelector(selectDocumentReferrer)
  const screenType = useSelector(selectScreenType)
  const isImpersonationMode = useSelector(selectIsImpersonationAuthorized)
  const dispatch = useDispatch()

  // tracking pages with segment
  useEffect(() => {
    const origin = window.document.location.origin
    const currentUrl = `${origin}${location.pathname}${location.search}`
    if (referrer !== currentUrl && !isImpersonationMode) {
      trackPage({ referrer, screenType })
    }

    return () => dispatch(setDocumentReferrer(currentUrl))
  }, [
    location.pathname,
    location.search,
    dispatch,
    referrer,
    screenType,
    isImpersonationMode,
  ])

  return null
}

export const DEPLOY_ENVS = {
  PREVIEW: 'preview',
  STAGING: 'staging',
  PRODUCTION: 'production',
}

export const NODE_ENVS = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
}

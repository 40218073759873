import * as braze from '@braze/web-sdk'
import CONFIG from '~/config'

const ensureInitialized = () => {
  if (braze.isInitialized()) return

  braze.initialize(CONFIG.BRAZE_APP_ID, {
    baseUrl: 'sdk.iad-05.braze.com',
    doNotLoadFontAwesome: true
  })
}

export const trackPageBraze = (properties = {}) => {
  ensureInitialized()
  if (!braze.getUser().getUserId()) return

  braze.logCustomEvent('Loaded a Page', {
    ...properties,
    path: window.location.pathname,
    search: window.location.search,
    url: window.location.href
  })
}

export const trackEventBraze = (eventName, eventData) => {
  ensureInitialized()
  if (!braze.getUser().getUserId()) return

  braze.logCustomEvent(eventName, eventData)
}

export const identifyUserBraze = user => {
  ensureInitialized()

  braze.changeUser(user.uuid)
}

export const wipeDataBraze = () => {
  ensureInitialized()

  braze.wipeData()
}

import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import { ExtraErrorData } from '@sentry/integrations'
import { registerSW } from 'virtual:pwa-register'
import NiceModal from '@ebay/nice-modal-react'

import CONFIG from '~/config'
import store from '~/store'
import '~/icons'
import '~/chart/index'
import '~/theme/index.css'
import ErrorBoundary from '~/containers/ErrorBoundary'
import App from '~/containers/App'
import {
  FpjsProvider,
  defaultEndpoint,
  defaultScriptUrlPattern
} from '@fingerprintjs/fingerprintjs-pro-react'
import reportWebVitals from './reportWebVitals'

if (!CONFIG.STORYBOOK) {
  if ('serviceWorker' in navigator) {
    registerSW({ immediate: true })
  }
}

if (CONFIG.SENTRY_DSN) {
  Sentry.init({
    dsn: CONFIG.SENTRY_DSN,
    release: CONFIG.COMMIT_SHA || `v${process.env.npm_package_version}`,
    environment: CONFIG.DEPLOY_ENV || CONFIG.ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing(),
      new ExtraErrorData({ depth: 5 }),
    ],
    normalizeDepth: 6,
    tracesSampleRate: 0.2,
  })
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <FpjsProvider
        loadOptions={{
          apiKey: CONFIG.FINGERPRINT_JS_API_KEY,
          endpoint: [
            'https://device.stellarfi.com/9wu7vqxs/yr7jyvua',
            defaultEndpoint
          ],
          scriptUrlPattern: [
            'https://device.stellarfi.com/9wu7vqxs/y0vbxeyy?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>',
            defaultScriptUrlPattern
          ]
        }}
      >
        <BrowserRouter>
          <ErrorBoundary>
            <NiceModal.Provider>
              <App />
            </NiceModal.Provider>
          </ErrorBoundary>
        </BrowserRouter>
      </FpjsProvider>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

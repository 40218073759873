import mixpanel from 'mixpanel-browser'

import CONFIG from '~/config'

const ensureInitialized = () => {
  /* eslint-disable no-underscore-dangle */
  if (mixpanel.__loaded) return
  /* eslint-enable no-underscore-dangle */

  mixpanel.init(CONFIG.MIXPANEL_PROJECT_ID, {})
}

export const trackPageMixpanel = (properties = {}) => {
  ensureInitialized()

  mixpanel.track_pageview(properties, {
    event_name: 'Loaded a Page'
  })
}

export const trackEventMixpanel = (eventName, eventData) => {
  ensureInitialized()

  mixpanel.track(eventName, eventData)
}

export const identifyUserMixpanel = user => {
  ensureInitialized()

  mixpanel.identify(user.uuid)
}

export const wipeDataMixpanel = () => {
  ensureInitialized()

  mixpanel.reset()
}
